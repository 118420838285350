//import logo from './logo.svg';
import './App.css';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  return (
    <div className="App">
      <Analytics/>
      <SpeedInsights/>
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          We're live!
        </p>
      </header>
    </div>
  );
}

export default App;
